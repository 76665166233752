:root {
  --scroll: #d0b530;
}

html.dark-mode {
  /**Dark mode*/
  --scroll: #fdfd96;
}

.progressMainWrapper {
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background: transparent;
}

.progressMainStyle {
  height: 5px;
  background-color: var(--scroll);
}
